import { defineAsyncComponent, defineComponent, h } from 'vue'
import { useAdmin, useHydrationStore } from '#imports'

export default defineComponent({
  name: 'AdminComponent',
  props: {
    is: {
      type: String,
      required: true
    }
  },
  setup (props, { attrs, slots }) {
    const components = useHydrationStore<any>('admin-components', {})
    const { isAdmin } = useAdmin()

    if (isAdmin.value && process.client && components.value[props.is]) {
      const el = defineAsyncComponent(() => (/* @vite-ignore */ import(`./${components.value[props.is]}/index.vue`)))

      return () => h(el, attrs, () => slots?.default?.())
    }

    return () => ''
  }
})
